<template>
    <div :class="{ 'focused' : focused }" class="bullet">
        <div class="row no-gutters flex-nowrap">
            <div class="col-auto">
                <img
                    v-if="bulletsDisplayType == 'bullet'"
                    class="bullet-icon"
                    :src="require(`@assets/img/icons/${ focused ? 'active-' : '' }bullet.svg`)"
                    :alt="`bullet-${bullet.id}`"
                >
                <div v-else class="numeric-icon" :class="{ 'focused' : focused }">
                    {{ bulletIndex + 1 }}
                </div>
            </div>
            <bullet-html-wrapper class="col bullet-html" :bullet-data="bullet.prettyText" />
        </div>
    </div>
</template>

<script>
export default {
    name: "Bullet",
    props: {
        bullet: {
            type: Object,
            required: true
        },
        focusedId: {
            type: Number,
            default: null
        },
        bulletsDisplayType: {
            type: String,
            default: "bullet"
        },
        bulletIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        focused() {
            return Boolean(this.bullet.id == this.focusedId)
        }
    }
}
</script>

<style lang="scss" scoped>
.bullet {
    margin-bottom: 30px;

    .bullet-html {
        font-size: 1.31em !important;
        line-height: 1.31em !important;
        font-weight: normal !important;
        font-family: "Gibson" !important;
        padding-bottom: 10px;

        /deep/ * {
            color: #D7D9DB !important;
            background-color: transparent !important;
            font-size: inherit !important;
            line-height: inherit !important;
            margin-bottom: 0;
        }

        /deep/ .ql-mention, /deep/ .ql-mention span {
            color: $primary-color !important;
        }

        /deep/ .ql-hashtag, /deep/ .ql-hashtag span {
            color: $primary-color !important;
        }

        /deep/ strong {
            color: white !important;
        }

        /deep/ em {
            font-style: italic;
        }

        /deep/ code {
            font-size: inherit;
            font-family: inherit;

            &:before {
                content: '"';
                font-style: italic;
            }

            &:after {
                content: '"';
                font-style: italic;
            }
        }

        /deep/ .image-container {
            img {
                width: 100%;
            }
        }
    }

    .bullet-icon {
        margin-top: 8px;
        margin-right: 20px;
        user-select: none;

        @media(max-width: $md) {
            margin-right: 10px;
        }
    }

    .numeric-icon {
        margin-right: 20px;
        font-size: 24px;
        font-family: 'Zilla Slab', serif;
        font-weight: 600;
        color: #656565;
        line-height: 24px;

        @media(max-width: $md) {
            margin-right: 10px;
        }

        &.focused {
            color: $primary-color;
        }
    }

    span {
        transition: opacity .5s ease;
        opacity: .6;
        line-height: 23px;
        font-size: 16px;
        margin-bottom: 0;
        display: block;

        @media(max-width: $md) {
            font-size: 18px;
            line-height: 26px;
        }

        /deep/ * {
            color: white !important;
            margin-bottom: 0;
            background-color: transparent !important;
        }
    }

    &.focused {
        span {
            opacity: 1;
        }
    }
}
</style>
